import { Component, OnInit, ViewChild } from '@angular/core';
import { CommunicatorService } from '../../services/common/communicator.service'
import { MenuItemData } from '../../data/types'

@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.css']
})
export class MenuPanelComponent implements OnInit {

  collapsibleElement: Element;
  @ViewChild("collapsible")
  set function(collapsible) {
    this.collapsibleElement = collapsible.nativeElement;
  }

  menus: MenuItemData[] = [
    {
      label: "Dashboard",
      icon: "assets/icons/upload.png",
      level: 0
    },
    {
      label: "Merge Files",
      icon: "assets/icons/upload.png",
      level: 0
    }
  ];

  constructor(private communicator: CommunicatorService) { }

  ngOnInit() {
    try {
      let __mat__ = <any>window['M'];

      __mat__.Collapsible.init(this.collapsibleElement, { accordion: false });
    } catch (ex) {
      console.log(ex);
    }
  }

}
