import { Component, OnInit, Input } from '@angular/core';
import { MenuItemData } from '../../data/types';

@Component({
  selector: 'menu-container',
  templateUrl: './menu-container.component.html',
  styleUrls: ['./menu-container.component.css']
})
export class MenuContainerComponent implements OnInit {

  private padding: number = 20;

  @Input('menuData') menuData: MenuItemData;

  hasSubmenus: boolean;
  paddingLeft: number = 15;
  expanded: boolean;

  constructor() { }

  ngOnInit() {
    const subMenus = this.menuData.subMenus;
    this.hasSubmenus = subMenus && subMenus.length > 0;
    this.paddingLeft = this.menuData.level * this.paddingLeft;
  }

  toogleExpandCollapse() {
    console.log("Menu label", this.menuData.label);
    if (this.hasSubmenus) {
      this.expanded = !this.expanded;
    }
  }

}
