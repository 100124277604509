import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'

import { AppComponent } from './app.component';
import { RootPanelComponent } from './containers/root-panel/root-panel.component';
import { HeaderPanelComponent } from './containers/header-panel/header-panel.component';
import { ContentPanelComponent } from './containers/content-panel/content-panel.component';
import { MenuPanelComponent } from './containers/menu-panel/menu-panel.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { MenuContainerComponent } from './containers/menu-container/menu-container.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ProgressComponent } from './components/progress/progress.component';
import { CiWizardComponent } from './components/ci-wizard/ci-wizard.component';

@NgModule({
  declarations: [
    AppComponent,
    RootPanelComponent,
    HeaderPanelComponent,
    ContentPanelComponent,
    MenuPanelComponent,
    MenuItemComponent,
    UserInfoComponent,
    MenuContainerComponent,
    FileUploadComponent,
    ProgressComponent,
    CiWizardComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
