export interface MenuItemData {
    label: string
    icon: string
    level: number
    selected?: boolean
    subMenus?: MenuItemData[]
}

export interface CrystalFile {
	file: any
	name: string
	size: string
	type: string
	modifiedDate: string
	progress: {
		showProgress?: boolean
		completed?: number
		status?: boolean
	}
}

export enum UploadStrategy {
    ONE_BY_ONE = 1,
    ALL_AT_ONCE = 2
}

export interface FileUploadConfig {
    url: string
    uploadStrategy?: UploadStrategy
    multiple?: boolean
    allowTypes?: string[]
    maxSize?: number
}
