import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileUploadService } from '../../services/fileupload/file-upload.service';

import { CrystalFile, FileUploadConfig, UploadStrategy } from '../../data/types';

@Component({
	selector: 'ci-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

	config: FileUploadConfig;
	@Input('config') set uploadConfig(config: FileUploadConfig) { 
		// assigning default values
		this.setDefaultConfigValues(config);
		this.config = config;
	}

	setDefaultConfigValues(config: FileUploadConfig) {
		if (typeof (config.multiple) != 'boolean') {
			config.multiple = false;
		}

		if (config.multiple) {
			if (!config.uploadStrategy) {
				config.uploadStrategy = UploadStrategy.ALL_AT_ONCE;
			}
		}

		if (typeof (config.maxSize) != 'number' || config.maxSize < 0) {
			config.maxSize = 0;
		}

		if (!Array.isArray(config.allowTypes)) {
			config.allowTypes = [];
		} else {
			config.allowTypes = config.allowTypes.map((ext, _) => ext.toLowerCase());
		}
	}
	
	inputElement: HTMLInputElement;
	@ViewChild('fileUpload')
	set fileUpload(fileUpload: ElementRef) {
		this.inputElement = fileUpload.nativeElement;
	}
	
	selectedFiles: CrystalFile[] = [];
	
	sizeIncr: number = 1000;
	sizeUnits: string[] = ['KB', 'MB', 'GB'];

	uploadStarted: boolean = false;

	constructor(private uploadService: FileUploadService) { }

	ngOnInit() {}

	openFilesDialog() {
		this.inputElement.click();
	}

	fileSelected() {
		const fileList = this.inputElement.files;
		let files: File[] = [];

		for (let i = 0; i < fileList.length; i++) {
			files.push(fileList.item(i));
		}

		console.log("#1", files);

		files = this.validateFiles(files);
		console.log("#2", files);

		for (let i = 0; i < files.length; i++) {
			let file = files[i];

			let fileObj: CrystalFile = {
				file: file,
				name: file.name,
				size: this.__getHumanReadableSize(file.size),
				type: file.type,
				modifiedDate: new Date(file.lastModified).toString(),
				progress: { }
			}
			this.selectedFiles.push(fileObj);
		}

		this.inputElement.value = "";
	}

	validateFiles(files: File[]) {
		files = this.filterTypes(files);

		if (this.config.maxSize > 0) {
			files = files.filter(file => {
				return !this.sizeExceedMaxSize(file);
			});
		}

		return files;
	}

	filterTypes(files: File[]) {
		if (this.config.allowTypes.length == 0) {
			return files;
		}

		var extExtractorRegEx = /\.([0-9a-z]+$)/i;
		return files.filter((file) => {
			var fileName = file.name;
			var ext = fileName.match(extExtractorRegEx)[1];

			return this.config.allowTypes.indexOf(ext.toLowerCase()) != -1;
		})
	}

	sizeExceedMaxSize(file: File) {
		return file.size > this.config.maxSize;
	}

	__getHumanReadableSize(size: number): string {
		if (size < 0 || !size) {
			return 'N/A';
		}

		size = size / this.sizeIncr;

		let count: number = 0;
		
		while (true) {
			// safty break
			if (count >= this.sizeUnits.length) break;

			if (size <= this.sizeIncr) {
				return `${size.toFixed(2)} ${this.sizeUnits[count]}`;
			}

			size = size / this.sizeIncr;
			count++;
		}

		return "Too big";
	}

	clearAllSelected() {
		this.selectedFiles.length = 0;
	}

	removeFile(file) {
		let index = this.selectedFiles.indexOf(file)

		if (index != -1) {
			this.selectedFiles.splice(index, 1);
		}
	}

	startUploading(event) {
		this.uploadStarted = true;
		// TODO check upload method here
		// possible options
		//  - async (default)
		//  - sync
		//  - allatonce
		for (let fileObj of this.selectedFiles) {
			// start progress bar
			fileObj.progress.showProgress = true;

			let formData = new FormData();
			formData.append("file", fileObj.file, fileObj.name);

			this.uploadService.uploadFile(this.config.url, formData).subscribe(
				(event: HttpEvent<Object>) => {
					this.handleEvent(fileObj, event);
				},
				(error) => { }
			);
		}
	}

	handleEvent(fileObj: CrystalFile, event: HttpEvent<Object>) {
		switch (event.type) {
			case HttpEventType.UploadProgress:
				let completed = (event.loaded / event.total) * 100;
				fileObj.progress.completed = parseFloat(completed.toFixed(2));

			case HttpEventType.Response:
				// TODO process response here
				// this responsibility can be delegate to caller
				break;

			case HttpEventType.ResponseHeader:
				if (event.ok) {
					fileObj.progress.status = true;
				} else {
					fileObj.progress.status = false;
				}
		}
	}
	
}
