import { Injectable } from '@angular/core';

/**
* A root service for sharing common data and
* service between components and another services
*/
@Injectable({
  providedIn: 'root'
})
export class CommunicatorService {

  rootWidth: number;
  rootHeight: number;

  headerHeight: number = 45;
  menuPanelWidth: number = 250;

  constructor() { }

}
