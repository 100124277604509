import { Component, OnInit } from '@angular/core';
import { FileUploadConfig, UploadStrategy } from 'src/app/data/types';

@Component({
  selector: 'app-content-panel',
  templateUrl: './content-panel.component.html',
  styleUrls: ['./content-panel.component.css']
})
export class ContentPanelComponent implements OnInit {

  private MAX_FILE_SIZE_IN_BYTES = 1024 * 200;

  fileUploadConfig: FileUploadConfig = {
    url: "http://localhost:8090/fileTransfer/upload",
    maxSize: this.MAX_FILE_SIZE_IN_BYTES,
    allowTypes: ["png", "jpeg", "jpg", "xlsx", "pdf"],
    multiple: true
  }

  constructor() { }

  ngOnInit() { }

}
