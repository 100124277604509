import { Component, OnInit, HostListener } from '@angular/core';
import { CommunicatorService } from '../../services/common/communicator.service'

@Component({
  selector: 'root-panel',
  templateUrl: './root-panel.component.html',
  styleUrls: ['./root-panel.component.css']
})
export class RootPanelComponent implements OnInit {

  constructor(public communicator: CommunicatorService) { }

  ngOnInit() {
    this.calculateAppBounds();
  }

  private calculateAppBounds() {
    this.communicator.rootWidth = window.innerWidth;
    this.communicator.rootHeight = window.innerHeight;
  }

  @HostListener('window:resize', ['$event'])
  private resizeHandler(event) {
    this.calculateAppBounds()
  }

}
