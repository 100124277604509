import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private httpClient: HttpClient) { }

  uploadFile(endpoint: string, formData: FormData): Observable<any> {
    return this.httpClient.post(endpoint, formData, {
      observe: "events",
      reportProgress: true
    });
  }

}
