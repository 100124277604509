import { Component, OnInit, Input } from '@angular/core';
import { CommunicatorService } from '../../services/common/communicator.service';
import { MenuItemData } from '../../data/types';

@Component({
  selector: 'menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {

  @Input('menuData') menuData: MenuItemData;
  @Input('hasSubmenus') hasSubmenus: boolean;

  paddingLeft: number;

  expanded: boolean;
  selected: boolean;

  constructor(private communicator: CommunicatorService) { }

  ngOnInit() {}

  performAction(event) {
    if (this.hasSubmenus) {
      this.expanded = !this.expanded;
    }
  }

}
