import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ci-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit {

  updatedProgress: number = 0;

  @Input('progress') 
  set progress(progress: number) {
    this.updatedProgress = progress || 0;
  }

  constructor() { }

  ngOnInit() {
  }

}
