import { Component, OnInit } from '@angular/core';
import { CommunicatorService } from '../../services/common/communicator.service'

@Component({
  selector: 'app-header-panel',
  templateUrl: './header-panel.component.html',
  styleUrls: ['./header-panel.component.css']
})
export class HeaderPanelComponent implements OnInit {

  constructor(public communicator: CommunicatorService) { }

  ngOnInit() {
  }

  private toogleMenuPanel() {
    if (this.communicator.menuPanelWidth > 0) {
      this.communicator.menuPanelWidth = 0;
    } else {
      this.communicator.menuPanelWidth = 250;
    }
  }

}
